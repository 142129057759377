import initialState from "../store/initialState";
import * as Actions from "./actions";

export const UserReducer = (state = initialState.user, action) => {
  switch (action.type) {
    case Actions.REGISTER:
      return {
        ...state,
        ...action.payload.user,
      };
    case Actions.REGISTER_ERROR:
      return {
        ...state,
        error: { register: action.payload.error.message },
      };
    case Actions.LOGIN:
      return {
        ...state,
        ...action.payload.user,
      };
    case Actions.LOGIN_ERROR:
      return {
        ...state,
        error: { login: action.payload.error.message },
      };
    case Actions.LOGOUT:
      return {
        state: {},
      };
    case Actions.CLEAR_ERROR_LOGIN:
      state.error.login.splice(action.payload.index, 1);
      return {
        ...state,
        error: {
          login: state.error.login,
        },
      };
    case Actions.CLEAR_ERROR_REGISTER:
      state.error.register.splice(action.payload.index, 1);
      return {
        ...state,
        error: {
          register: state.error.register,
        },
      };

    case Actions.SIGN_USER_STORE:
      return {
        ...state,
        ...action.payload.user,
      };
    default:
      return state;
  }
};
