import { connectRouter, routerMiddleware } from "connected-react-router";
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore as reduxCreateStore,
} from "redux";
import thunk from "redux-thunk";

import { ComplaintReducer } from "../complaint/reducers";
import { TaxTypeReducer } from "../tax-type/reducers";
import { UserReducer } from "../users/reducers";

export default function createStore(history) {
  return reduxCreateStore(
    combineReducers({
      router: connectRouter(history),
      user: UserReducer,
      taxTypes: TaxTypeReducer,
      complaints: ComplaintReducer,
    }),
    compose(
      applyMiddleware(routerMiddleware(history), thunk)
      // DEBUG MODE
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  );
}
