import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
  Toolbar,
} from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import API from "../API";
import uploadImage from "../assets/images/upload-area.svg";
import AppBarHeader from "../components/general/AppBarHeader";
import DrawerSidebar from "../components/general/DrawerSidebar";

const mdTheme = createTheme();

export default function CreateUpdateAdvertisement() {
  const api = new API();
  const [image, setImage] = useState(null);
  const [imageMobile, setImageMobile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewImageMobile, setPreviewImageMobile] = useState(null);
  const inputFileLandscape = useRef(null);
  const inputFilePortrait = useRef(null);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const history = useHistory();
  const [open, setOpen] = useState(true);
  const [isUpdate, setIsUpdate] = useState(false);

  const action = history.location.state ? history.location.state.action : "";
  const id = history.location.state ? history.location.state.id : null;

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const [values, setValues] = useState({
    name: "",
    image: "",
    imageUrl: "",
    imageMobile: "",
    imageMobileUrl: "",
  });

  const inputImageLandscape = (event) => {
    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    setPreviewImage(objectUrl);
    setImage(file);
  };

  const inputImagePortrait = (event) => {
    const file = event.target.files[0];
    const objectUrl = URL.createObjectURL(file);
    setPreviewImageMobile(objectUrl);
    setImageMobile(file);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (isUpdate) {
      const uploadPromises = [];
      if (image) {
        uploadPromises.push(api.uploadImage(image));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      if (imageMobile) {
        uploadPromises.push(api.uploadImage(imageMobile));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      Promise.all(uploadPromises)
        .then((res) => {
          if (res && res.STATUS_CODE === "200") {
          }
          api
            .updateAdvertisement(
              {
                page: "home",
                position: "popup",
                name: values.name,
                image: res[0] ? res[0].DATA[0].filename : values.image,
                imageMobile: res[1]
                  ? res[1].DATA[0].filename
                  : values.imageMobile,
              },
              id
            )
            .then((res) => {
              if (res && res.STATUS_CODE === "200") {
                Swal.fire({ title: "Success", icon: "success" }).then(() => {
                  history.push({ pathname: "/advertisement" });
                });
              } else {
                Swal.fire({
                  title: "Fail",
                  icon: "fail",
                  message: res.MESSAGE,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const uploadPromises = [];
      if (image) {
        uploadPromises.push(api.uploadImage(image));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      if (imageMobile) {
        uploadPromises.push(api.uploadImage(imageMobile));
      } else {
        uploadPromises.push(new Promise((resolve) => resolve(false)));
      }
      Promise.all(uploadPromises)
        .then((res) => {
          api
            .createAdvertisement(
              {
                page: "home",
                position: "popup",
                name: values.name,
                image: res[0] ? res[0].DATA[0].filename : values.image,
                imageMobile: res[1]
                  ? res[1].DATA[0].filename
                  : values.imageMobile,
              },
              id
            )
            .then((res) => {
              if (res && res.STATUS_CODE === "200") {
                Swal.fire({ title: "Success", icon: "success" }).then(() => {
                  history.push({ pathname: "/advertisement" });
                });
              } else {
                const html =
                  res.ERRORS.length > 0
                    ? res.ERRORS.map(
                        (message, i) =>
                          `<p style="text-align: left">${i + 1}. ${message}</p>`
                      ).join("")
                    : "";
                Swal.fire({
                  title: "Fail",
                  icon: "fail",
                  html,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (action === "Update") {
      api.getAdvertisementDetail(id).then((res) => {
        setValues(res.DATA);
      });
    }
    setIsUpdate(action === "Update");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBarHeader
            headerName="Advertisement"
            open={open}
            toggleDrawer={toggleDrawer}
          />
          <DrawerSidebar
            menu="advertisement"
            open={open}
            toggleDrawer={toggleDrawer}
          />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <div
                className="add-new-button"
                style={{ justifyContent: "start" }}
              >
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBackIcon />
                </IconButton>
                <p>{action} Advertisement</p>
              </div>
              <Grid item lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item lg={12}>
                        <TextField
                          autoFocus
                          fullWidth
                          id="name"
                          label="Ads Name"
                          name="name"
                          onChange={handleInputChange}
                          required
                          value={values.name || ""}
                        />
                      </Grid>
                      {values.imageUrl ? (
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{
                              width: "70%",
                              border: "2px solid #ffd801",
                            }}
                            src={previewImage ? previewImage : values.imageUrl}
                            alt="item"
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          md={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            style={{ width: "50%" }}
                            src={previewImage ? previewImage : uploadImage}
                            alt="item"
                          />
                        </Grid>
                      )}

                      <Grid
                        item
                        md={12}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{ marginBottom: "18px" }}
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Web Image
                          <VisuallyHiddenInput
                            ref={inputFileLandscape}
                            onChange={inputImageLandscape}
                            type="file"
                          />
                        </Button>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      {values.imageMobileUrl ? (
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{
                              width: "70%",
                              border: "2px solid #ffd801",
                            }}
                            src={
                              previewImageMobile
                                ? previewImageMobile
                                : values.imageMobileUrl
                            }
                            alt="item"
                          />
                        </Grid>
                      ) : (
                        <Grid
                          item
                          md={12}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            style={{ width: "50%" }}
                            src={
                              previewImageMobile
                                ? previewImageMobile
                                : uploadImage
                            }
                            alt="item"
                          />
                        </Grid>
                      )}

                      <Grid
                        item
                        md={12}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload Mobile Image
                          <VisuallyHiddenInput
                            ref={inputFilePortrait}
                            onChange={inputImagePortrait}
                            type="file"
                          />
                        </Button>
                      </Grid>
                    </Grid>

                    <Button
                      disabled={
                        isUpdate
                          ? false
                          : values.name && imageMobile && image
                          ? false
                          : true
                      }
                      type="button"
                      onClick={handleSubmit}
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                    >
                      {action}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
