import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Alert } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { clearErrorLoginAction } from "../reducks/users/actions";

import { login } from "../reducks/users/operations";
import { getUser } from "../reducks/users/selectors";

const theme = createTheme();

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selector = useSelector((state) => state);
  const user = getUser(selector);
  const initialValues = {
    username: "",
    password: "",
  };

  const [values, setValues] = useState(initialValues);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const clearLoginError = (index) => {
    dispatch(clearErrorLoginAction(index));
  };

  const handleSubmit = () => {
    dispatch(
      login(values, () => {
        history.push({ pathname: "/supply-item" });
      })
    );
  };

  return (
    <>
      {user.error.login &&
        user.error.login.map((r, index) => (
          <>
            <Alert
              key={r}
              onClose={() => {
                clearLoginError(index);
              }}
              variant="filled"
              severity="error"
            >
              {r}
            </Alert>
            <br />
          </>
        ))}
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>

            <TextField
              autoFocus
              autoComplete="username"
              fullWidth
              id="username"
              label="Username"
              margin="normal"
              name="username"
              onChange={handleInputChange}
              required
              value={values.username}
            />
            <TextField
              autoComplete="current-password"
              fullWidth
              id="password"
              label="Password"
              margin="normal"
              name="password"
              onChange={handleInputChange}
              required
              type="password"
              value={values.password}
            />
            <Button
              type="button"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item>
                <Link className="auth-link" to="/register">
                  Don't have an account? Register
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
