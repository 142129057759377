import initialState from "../store/initialState";
import * as Actions from "./actions";

export const ComplaintReducer = (state = initialState.complaints, action) => {
  switch (action.type) {
    case Actions.FETCH_COMPLAINTS:
      return {
        ...state,
        ...action.payload.complaints,
      };
    case Actions.UPDATE_COMPLAINT:
      return {
        ...state,
        ...action.payload.complaints,
      };
    case Actions.DETAIL_COMPLAINT:
      return {
        ...state,
        detail: action.payload.complaint,
      };
    case Actions.ACCEPTED_COMPLAINT:
      return {
        ...state,
        ...action.payload.complaints,
      };
    default:
      return state;
  }
};
