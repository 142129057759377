export const FETCH_TAX_TYPES = "FETCH_TAX_TYPES";
export const fetchTaxTypesAction = (taxTypes) => {
  return {
    type: FETCH_TAX_TYPES,
    payload: { taxTypes },
  };
};

export const ADD_TAX_TYPE = "ADD_TAX_TYPE";
export const addTaxTypeAction = (taxType) => {
  return {
    type: ADD_TAX_TYPE,
    payload: { taxType },
  };
};

export const UPDATE_TAX_TYPE = "UPDATE_TAX_TYPE";
export const updateTaxTypeAction = (taxType) => {
  return {
    type: UPDATE_TAX_TYPE,
    payload: { taxType },
  };
};

export const CREATE_UPDATE_ERROR = "CREATE_UPDATE_ERROR";
export const taxTypeError = (errors) => {
  return {
    type: CREATE_UPDATE_ERROR,
    payload: {
      errors,
    },
  };
};

export const CLEAR_ERROR = "CLEAR_ERROR";
export const clearErrorTaxTypeAction = (index) => {
  return {
    type: CLEAR_ERROR,
    payload: { index },
  };
};
