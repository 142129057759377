import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router";

import { logout } from "../../reducks/users/operations";
import AppBar from "./AppBar";

export default function AppBarHeader(props) {
  const { open, toggleDrawer, headerName } = props;
  const history = useHistory();

  const onClickLogout = () => {
    logout(() => history.push({ pathname: "/login" }));
  };
  return (
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {headerName}
          </Typography>
          <IconButton onClick={onClickLogout} color="inherit">
            <LogoutIcon></LogoutIcon>
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}
