import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { clearErrorRegisterAction } from "../reducks/users/actions";
import { register } from "../reducks/users/operations";
import { getUser } from "../reducks/users/selectors";

const theme = createTheme();

export default function Register() {
  const history = useHistory();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const user = getUser(selector);

  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "female",
    placeOfBirth: "",
    address: "",
    phoneNumber: "",
    email: "",
    password: "",
    employeeId: "",
    duty: "",
    unit: "",
  };

  const [values, setValues] = useState(initialValues);
  const [dateOfBirth, setDateOfBirth] = useState(moment("1990-01-01").format());

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const clearRegisterError = (index) => {
    dispatch(clearErrorRegisterAction(index));
  };

  const handleSubmit = () => {
    dispatch(
      register({ ...values, dateOfBirth }, () => {
        history.push({ pathname: "/complaint" });
        dispatch(clearErrorRegisterAction());
      })
    );
  };

  return (
    <>
      {user.error.register &&
        user.error.register.map((r, index) => (
          <>
            <br />
            <Alert
              key={r}
              onClose={() => {
                clearRegisterError(index);
              }}
              variant="filled"
              severity="error"
            >
              {r}
            </Alert>
          </>
        ))}
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoFocus
                    autoComplete="given-name"
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    onChange={handleInputChange}
                    required
                    value={values.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="family-name"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    onChange={handleInputChange}
                    required
                    value={values.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      fullWidth
                      id="dateOfBirth"
                      label="Date Of Birth"
                      name="dateOfBirth"
                      onChange={(e) => {
                        setDateOfBirth(moment(e).format());
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      value={dateOfBirth}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Gender
                      </InputLabel>
                      <Select
                        id="demo-simple-select"
                        label="Gender"
                        labelId="demo-simple-select-label"
                        name="gender"
                        onChange={handleInputChange}
                        value={values.gender}
                      >
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="male">Male</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoComplete="email"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={handleInputChange}
                    required
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="new-password"
                    fullWidth
                    id="password"
                    label="Password"
                    name="password"
                    onChange={handleInputChange}
                    required
                    type="password"
                    value={values.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="address-line1"
                    fullWidth
                    id="placeOfBirth"
                    label="Place Of Birth"
                    name="placeOfBirth"
                    onChange={handleInputChange}
                    required
                    value={values.placeOfBirth}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="address-line1"
                    fullWidth
                    id="address"
                    label="Address"
                    name="address"
                    onChange={handleInputChange}
                    required
                    value={values.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="tel"
                    fullWidth
                    id="phoneNumber"
                    label="Phone Number"
                    name="phoneNumber"
                    onChange={handleInputChange}
                    required
                    value={values.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="employeeId"
                    label="Employee ID"
                    name="employeeId"
                    onChange={handleInputChange}
                    required
                    value={values.employeeId}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="duty"
                    label="Duty"
                    name="duty"
                    onChange={handleInputChange}
                    required
                    value={values.duty}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="unit"
                    label="Unit"
                    name="unit"
                    onChange={handleInputChange}
                    required
                    value={values.unit}
                  />
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Register
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link className="auth-link" to="/login">
                    Already have an account? Login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
