import initialState from "../store/initialState";
import * as Actions from "./actions";

export const TaxTypeReducer = (state = initialState.taxTypes, action) => {
  switch (action.type) {
    case Actions.FETCH_TAX_TYPES:
      return {
        ...state,
        ...action.payload.taxTypes,
      };

    case Actions.ADD_TAX_TYPE:
      return {
        ...state,
      };

    case Actions.CREATE_UPDATE_ERROR:
      return {
        ...state,
        errors: action.payload.errors.message,
      };

    case Actions.CLEAR_ERROR:
      state.errors.splice(action.payload.index, 1);
      return {
        ...state,
        errors: state.errors,
      };

    case Actions.UPDATE_TAX_TYPE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
