import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import API from "../API";
import AppBarHeader from "../components/general/AppBarHeader";
import DrawerSidebar from "../components/general/DrawerSidebar";
import MainSection from "../components/general/MainSection";
import Swal from "sweetalert2";

const mdTheme = createTheme();

export default function SupplyItem() {
  const history = useHistory();

  const [supplyItems, setSupplyItems] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 100,
    offset: 0,
    page: 1,
    total: 0,
  });

  const isSuccess = history.location.state
    ? history.location.state.isSuccess
    : "";

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const createData = (
    id,
    no,
    itemType,
    itemImageLandscapeUrl,
    itemImagePortraitUrl,
    status,
    createdAt
  ) => {
    const statusText = status === "active" ? "Active" : "Inactive";
    const createData = moment(createdAt).format("MMMM Do YYYY");
    return {
      id,
      no,
      itemType,
      itemImageLandscapeUrl,
      itemImagePortraitUrl,
      status: statusText,
      createdAt: createData,
    };
  };

  const addNewHandler = () => {
    history.push({
      pathname: `/supply-item/create`,
      state: { action: "Create" },
    });
  };

  const updateHandler = (id) => {
    history.push({
      pathname: `/supply-item/update/${id}`,
      state: { action: "Update", id },
    });
  };

  const deleteHandler = (id) => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        api.deleteSupplyItem(id).then((res) => {
          if (res && res.STATUS_CODE === "200") {
            const text = `Advertisement Successfully Deleted`;
            Swal.fire({
              title: "Success",
              text,
              icon: "success",
            }).then(() => {
              getSupplyItems();
            });
          }
        });
      }
    });
  };

  const columns = [
    { id: "no", label: "No", minWidth: 100, align: "left" },
    { id: "itemType", label: "Item Type", minWidth: 170 },
    { id: "status", label: "Status", minWidth: 170, align: "center" },
    { id: "createdAt", label: "Created Date", minWidth: 170 },
  ];

  const api = new API();

  const getSupplyItems = () => {
    api
      .getSupplyItems()
      .then((res) => {
        setSupplyItems(res.DATA);
        setPagination(res.METADATA);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    getSupplyItems();
    // eslint-disable-next-line
  }, []);

  const rows = supplyItems.map((item, index) => {
    if (item) {
      return createData(
        item.id,
        index + 1,
        item.itemType,
        item.itemImageLandscapeUrl,
        item.itemImagePortraitUrl,
        item.status,
        item.createdAt
      );
    }
    return null;
  });

  const onToggleStatus = (id) => {
    Swal.fire({
      title: "Warning",
      text: "Are you sure you want to change status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        api.toggleSupplyItemStatus(id).then((res) => {
          const text = `Supply Item status updated to ${
            res === "active" ? "Active" : "Inactive"
          }`;
          Swal.fire({
            title: "Success",
            text,
            icon: "success",
          }).then(() => {
            getSupplyItems();
          });
        });
      }
    });
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBarHeader
          headerName="Supply Item"
          open={open}
          toggleDrawer={toggleDrawer}
        />
        <DrawerSidebar
          menu="supply-item"
          open={open}
          toggleDrawer={toggleDrawer}
        />
        <MainSection
          columns={columns}
          rows={rows}
          pagination={pagination}
          addNewHandler={addNewHandler}
          updateHandler={updateHandler}
          deleteHandler={deleteHandler}
          onToggleStatus={onToggleStatus}
          addNew={true}
          isSuccess={isSuccess}
          title="Supply Item List"
        />
      </Box>
    </ThemeProvider>
  );
}
