import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

import Advertisement from "./containers/Advertisement";
import CreateUpdateAdvertisement from "./containers/CreateUpdateAdvertisement";
import CreateUpdateSupplyItem from "./containers/CreateUpdateSupplyItem";
import Login from "./containers/Login";
import PageDenied from "./containers/PageDenied";
import Register from "./containers/Register";
import SupplyItem from "./containers/SupplyItem";
import { fetchUserFromLocalStorage } from "./reducks/users/operations";
import { getUser } from "./reducks/users/selectors";

const Router = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const data = getUser(selector);
  const token = data.DATA ? data.DATA.accessToken : null;

  useEffect(() => {
    dispatch(fetchUserFromLocalStorage());
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Route exact path={"/"} component={Login} />
      <Route exact path={"/login"} component={Login} />
      <Route exact path={"/register"} component={Register} />
      <Route
        exact
        path={"/supply-item"}
        component={token ? SupplyItem : PageDenied}
      />
      <Route
        exact
        path={"/supply-item/:action/:id?"}
        component={token ? CreateUpdateSupplyItem : PageDenied}
      />
      <Route
        exact
        path={"/advertisement"}
        component={token ? Advertisement : PageDenied}
      />
      <Route
        exact
        path={"/advertisement/:action/:id?"}
        component={token ? CreateUpdateAdvertisement : PageDenied}
      />
    </Switch>
  );
};
export default Router;
