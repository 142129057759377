const initialState = {
  user: {
    error: {
      login: null,
      register: null,
    },
  },

  taxTypes: {
    metadata: {
      total: 0,
      limit: 10,
      offset: 0,
      page: 1,
    },
    errors: null,
    data: [],
  },
  complaints: {
    metadata: {
      total: 0,
      limit: 10,
      offset: 0,
      page: 1,
    },
    detail: null,
    errors: null,
    data: [],
  },
};

export default initialState;
