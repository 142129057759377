export const REGISTER = "REGISTER";
export const registerAction = (user) => {
  return {
    type: REGISTER,
    payload: {
      user,
    },
  };
};

export const REGISTER_ERROR = "REGISTER_ERROR";
export const registerError = (error) => {
  return {
    type: REGISTER_ERROR,
    payload: {
      error,
    },
  };
};

export const LOGIN = "LOGIN";
export const loginAction = (user) => {
  return {
    type: LOGIN,
    payload: {
      user,
    },
  };
};

export const LOGIN_ERROR = "LOGIN_ERROR";
export const loginError = (error) => {
  return {
    type: LOGIN_ERROR,
    payload: {
      error,
    },
  };
};

export const LOGOUT = "LOGOUT";
export const logoutAction = () => {
  return {
    type: LOGOUT,
  };
};

export const CLEAR_ERROR_LOGIN = "CLEAR_ERROR_LOGIN";
export const clearErrorLoginAction = (index) => {
  return {
    type: CLEAR_ERROR_LOGIN,
    payload: { index },
  };
};

export const CLEAR_ERROR_REGISTER = "CLEAR_ERROR_REGISTER";
export const clearErrorRegisterAction = (index) => {
  return {
    type: CLEAR_ERROR_REGISTER,
    payload: { index },
  };
};

export const SIGN_USER_STORE = "SIGN_USER_STORE";
export const signUserStoreAction = (user) => {
  return {
    type: SIGN_USER_STORE,
    payload: {
      user,
    },
  };
};
