export const FETCH_COMPLAINTS = "FETCH_COMPLAINTS";
export const fetchComplaintAction = (complaints) => {
  return {
    type: FETCH_COMPLAINTS,
    payload: { complaints },
  };
};

export const UPDATE_COMPLAINT = "UPDATE_COMPLAINT";
export const updateComplaintAction = (complaint) => {
  return {
    type: UPDATE_COMPLAINT,
    payload: { complaint },
  };
};

export const ACCEPTED_COMPLAINT = "ACCEPTED_COMPLAINT";
export const acceptedComplaintAction = (complaint) => {
  return {
    type: ACCEPTED_COMPLAINT,
    payload: { complaint },
  };
};

export const DETAIL_COMPLAINT = "DETAIL_COMPLAINT";
export const detailComplaintAction = (complaint) => {
  return {
    type: DETAIL_COMPLAINT,
    payload: { complaint },
  };
};
