import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Alert,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";
import Box from "@mui/material/Box";
import moment from "moment";
import React, { useState } from "react";

import CustomTable from "../table/CustomTable";

export default function MainSection(props) {
  const {
    addNew = false,
    isSuccess = false,
    isQuery = false,
    setStatus,
    status,
    setDate,
    date = null,
    addNewHandler,
    updateHandler,
    deleteHandler,
    onToggleStatus,
    title,
    columns,
    rows,
    pagination,
  } = props;

  const [onSuccess, setOnSuccess] = useState(isSuccess);

  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
          {onSuccess && (
            <>
              <Alert
                variant="filled"
                severity="success"
                onClose={() => {
                  setOnSuccess(false);
                }}
              >
                Successfully.
              </Alert>
              <br />
            </>
          )}
          <div className="add-new-button">
            <p>{title}</p>
            {addNew && (
              <Button onClick={addNewHandler} variant="contained">
                Add New
              </Button>
            )}
          </div>
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              {isQuery && (
                <Grid sx={{ mb: "20px" }} container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          id="demo-simple-select"
                          label="Status"
                          labelId="demo-simple-select-label"
                          name="status"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <MenuItem value="">All</MenuItem>
                          <MenuItem value="submitted">Submitted</MenuItem>
                          <MenuItem value="progress">Progress</MenuItem>
                          <MenuItem value="resolved">Resolved</MenuItem>
                          <MenuItem value="responded">Responded</MenuItem>
                          <MenuItem value="closed">Closed</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        fullWidth
                        id="date"
                        label="Complaint Date"
                        name="date"
                        onChange={(e) => {
                          setDate(e ? moment(e).format("YYYY-MM-DD") : null);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        value={date}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              )}

              <CustomTable
                updateHandler={updateHandler}
                deleteHandler={deleteHandler}
                onToggleStatus={onToggleStatus}
                rows={rows}
                columns={columns}
                pagination={pagination}
              />
            </Paper>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
