import API, { LOGIN_USER_KEY } from "../../API";
import {
  loginAction,
  loginError,
  registerAction,
  registerError,
  signUserStoreAction,
} from "./actions";

const api = new API();

export const fetchUserFromLocalStorage = () => {
  return (dispatch) => {
    const userJSON = localStorage.getItem(LOGIN_USER_KEY);
    if (userJSON && userJSON.accessToken !== "") {
      dispatch(signUserStoreAction(JSON.parse(userJSON)));
    }
  };
};

export const register = (data = {}, onSuccess = null) => {
  return async (dispatch) => {
    return api
      .register(data)
      .then((response) => {
        localStorage.setItem(LOGIN_USER_KEY, JSON.stringify(response));
        dispatch(registerAction(response));
        onSuccess();
      })
      .catch((error) => {
        dispatch(registerError(error.response.data));
      });
  };
};

export const login = (data = {}, onSuccess = null) => {
  return async (dispatch) => {
    return api
      .login(data)
      .then((response) => {
        localStorage.setItem(LOGIN_USER_KEY, JSON.stringify(response));
        dispatch(loginAction(response));
        onSuccess();
      })
      .catch((error) => {
        dispatch(loginError(error.response.data));
      });
  };
};

export const logout = (onSuccess = null) => {
  localStorage.removeItem(LOGIN_USER_KEY);
  onSuccess();
};
